.Menu-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  z-index: 3;
}

.Menu-content {
  height: 100%;
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-rows: auto auto auto;
  align-content: flex-start;
  animation: Menu-content-animation ease 0.4s;
}

@keyframes Menu-content-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.Menu-content-v1 {
  animation: Menu-content-v1-animation ease 0.4s;
}

@keyframes Menu-content-v1-animation {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.Menu-B1 {
  height: fit-content;
  width: calc(100% - 3rem);
  padding: 1.2rem 1.5rem 1.2rem 1.5rem;
  border-bottom: 0.05rem solid rgb(128, 128, 128, 0.2);
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
}

.Menu-img-1 {
  height: 1.5rem;
  width: auto;
  cursor: pointer;
}

.Menu-svg-1 {
  height: 1.3rem;
  width: auto;
  fill: white;
  background-color: rgb(112, 112, 112);
  border-radius: 50%;
  cursor: pointer;
}

.Menu-B2 {
  height: fit-content;
  width: calc(100% - 3rem);
  padding: 1.2rem 1.5rem 1.2rem 1.5rem;
  border-bottom: 0.05rem solid rgb(128, 128, 128, 0.2);
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-row-gap: 1.5rem;
  align-content: flex-start;
}

.Menu-txt-1 {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 600;
  color: gray;
  margin: 0 0 0 0.5rem;
}

.Menu-B2B1 {
  height: fit-content;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}

.Menu-txt-2 {
  margin: 0;
  font-size: 1rem;
  font-weight: 800;
  color: black;
}

.Menu-svg-2 {
  height: 0.9rem;
  width: auto;
  fill: gray;
}

.Menu-B3 {
  height: fit-content;
  width: calc(100% - 3rem);
  padding: 1.2rem 1.5rem 1.2rem 1.5rem;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 1.5rem;
  align-content: flex-start;
}

.Menu-txt-3 {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 600;
  color: gray;
}

.Menu-txt-4 {
  width: fit-content;
  margin: 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: rgb(98, 98, 98);
  cursor: pointer;
}

.Menu-Shadow {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  background-color: rgb(44, 44, 44, 0.5);
}

.Menu-Shadow-v1 {
  background: transparent;
}

@media (min-width: 1024px) {
  .Menu-content {
    width: 17.5rem;
  }
}
