html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: white;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #c4c4c4 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 1.5vh;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 20px;
  border: 1px solid #ffffff;
}

.NoScroll {
  /* when modal active */
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  /* Other browsers */
  overscroll-behavior: none;
  scroll-behavior: none;
  height: auto;
}

.NotDisplayed {
  display: none;
}

.NoClickEvent {
  pointer-events: none;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 75%, 0.3);
  }

  100% {
    background-color: hsl(0, 0%, 95%, 0.3);
  }
}
