.ContactPage-background {
  padding: 6rem 0;
  background: rgb(194, 226, 255);
  background: linear-gradient(
    180deg,
    rgba(194, 226, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.ContactPage-content {
  min-width: 15rem;
  width: calc(98% - 3rem);
  padding: 2rem 1.5rem 2rem 1.5rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0px 5px 10px -5px rgba(80, 80, 80, 0.5);
  -webkit-box-shadow: 0px 5px 10px -5px rgba(80, 80, 80, 0.5);
  -moz-box-shadow: 0px 5px 10px -5px rgba(80, 80, 80, 0.5);
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 1.5rem;
  align-content: flex-start;
}

.ContactPage-txt-1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 800;
  border-bottom: 0.05rem solid rgb(128, 128, 128, 0.5);
  padding: 0 0 0.5rem 0;
}

.ContactPage-txt-2 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

.ContactPage-txt-3 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
  color: gray;
}

.ContactPage-B1 {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 1rem;
}

.ContactPage-B1B1 {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.ContactPage-svg-1 {
  background-color: rgb(220, 220, 220);
  border-radius: 0.2rem;
  padding: 0.3rem;
}

.ContactPage-txt-4 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
  color: black;
}

.ContactPage-txt-5 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
  color: gray;
  text-align: center;
}

@media (min-width: 648px) {
  .ContactPage-content {
    max-width: 40rem;
  }
}
