.Navbar-background {
  height: 3.75rem;
  width: calc(100% - 3rem);
  padding: 0 1.5rem 0 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  z-index: 3;
}

.Navbar-B1 {
  display: flex;
  justify-content: flex-start;
}

.Navbar-B2 {
  display: flex;
  justify-content: flex-end;
}

.Navbar-B1B1 {
  display: flex;
  align-items: center;
}

.Navbar-svg-1 {
  height: 1.3rem;
  width: auto;
  fill: rgb(64, 64, 64);
  cursor: pointer;
}

.Navbar-img-1 {
  display: none;
  cursor: pointer;
}

.Navbar-txt-1 {
  display: none;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .Navbar-B1B1 {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 1.5rem;
  }

  .Navbar-img-1 {
    height: 1.5rem;
    width: auto;
    display: block;
  }

  .Navbar-txt-1 {
    margin: 0;
    display: inline;
    font-size: 0.9rem;
    font-weight: 700;
    color: rgb(64, 64, 64);
  }
}
