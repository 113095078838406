.Searcher-background {
  height: 3rem;
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Searcher-input {
  margin: 0;
  padding: 0;
  padding: 0 2rem 0 2rem;
  height: 100%;
  width: 100%;
  border: none;
  background-color: whitesmoke;
  font-family: "Nunito", sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  color: gray;
  border-radius: 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Searcher-input:focus {
  outline: none;
}

.Searcher-svg-1 {
  right: 0.7rem;
  height: 1.3rem;
  width: auto;
  fill: #606060;
  position: absolute;
  cursor: pointer;
}
