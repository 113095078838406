.HomePage-background {
  height: fit-content;
  width: 100%;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 2rem;
  padding: 3.75rem 0 3.75rem 0;
}

.HomePage-B1 {
  background: rgb(51, 148, 236);
  background: linear-gradient(
    52deg,
    rgba(51, 148, 236, 0.05) 0%,
    rgba(51, 160, 236, 0.25) 100%
  );
  height: fit-content;
  width: calc(100% - 3rem);
  padding: 0.7rem 1.5rem 0.7rem 1.5rem;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.2rem;
  justify-items: flex-start;
}

.HomePage-txt-1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 800;
  color: #2796ff;
}

.HomePage-B1B1 {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
}

.HomePage-txt-2 {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
  color: black;
}
