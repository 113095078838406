.Explorer-background {
  height: fit-content;
  width: calc(100% - 3rem);
  padding: 0 1.5rem 0 1.5rem;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 1.5rem;
}

.Explorer-B1 {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.5rem;
  overflow-y: hidden;
  overflow-x: auto;
  justify-content: flex-start;
}

.Explorer-B1::-webkit-scrollbar {
  display: none;
}

.Explorer-B2 {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0.05rem;
}

.Explorer-B2::-webkit-scrollbar {
  display: none;
}

.SecondExplorer-txt-1 {
  display: none;
}

.SecondExplorer-B2B1 {
  padding: 0.4rem 0.6rem 0.4rem 0.6rem;
  width: fit-content;
  display: grid;
  grid-template-columns: min-content max-content;
  grid-column-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  border: 0.05rem solid rgb(128, 128, 128, 0.2);
  border-radius: 0.6rem;
  cursor: pointer;
}

.SecondExplorer-B2B1-selected {
  background-color: rgb(39, 150, 255, 0.15);
  border: 0.05rem solid #2796ff;
}

.SecondExplorer-svg-1 {
  fill: #ffae00;
}

.SecondExplorer-svg-1-selected {
  fill: #2796ff;
}

.SecondExplorer-txt-2 {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
  color: rgb(84, 84, 84);
}

.SecondExplorer-txt-2-selected {
  color: #2796ff;
  font-weight: 700;
}

/*ExplorerMobile*/

.ExplorerMobile-B1 {
  height: 3.3rem;
  width: 4.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 10px -5px rgba(80, 80, 80, 0.5);
  -webkit-box-shadow: 0px 5px 10px -5px rgba(80, 80, 80, 0.5);
  -moz-box-shadow: 0px 5px 10px -5px rgba(80, 80, 80, 0.5);
  transition: 0.5s;
  cursor: pointer;
}

.ExplorerMobile-B1:hover {
  transform: scale(1.08);
}

.ExplorerMobile-B1B1 {
  display: grid;
  grid-template-rows: auto auto;
  align-content: flex-start;
  justify-items: center;
}

.ExplorerMobile-svg-1 {
  background-color: #2796ff;
  border-radius: 50%;
  padding: 0.3rem;
}

.ExplorerMobile-txt-1 {
  margin: 0;
  color: #2796ff;
  font-size: 0.8rem;
  font-weight: 700;
}

/*ExplorerProductsFromDB*/

.ExplorerProductsFromDB-background {
  height: fit-content;
  width: min-content;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.1rem;
  cursor: pointer;
  transition: 0.5s;
}

.ExplorerProductsFromDB-background:hover {
  transform: scale(1.08);
}

.ExplorerProductsFromDB-B1 {
  height: 3.3rem;
  width: 4.5rem;
  border: 0.13rem solid transparent;
  border-radius: 1.13rem;
  position: relative;
}

.ExplorerProductsFromDB-B1-selected {
  border: 0.13rem solid #2796ff;
}

.ExplorerProductsFromDB-img-1 {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.ExplorerProductsFromDB-img-1-selected {
  opacity: 0.2;
}

.ExplorerProductsFromDB-svg-1 {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
}

.ExplorerProductsFromDB-txt-1 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  color: rgb(94, 94, 94);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 0.13rem 0 0.13rem;
}

.ExplorerProductsFromDB-txt-1-selected {
  color: #2796ff;
  font-weight: 700;
}

/*ExplorerLoader*/

.ExplorerLoader-background {
  height: fit-content;
  width: min-content;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.3rem;
}

.ExplorerLoader-B1 {
  height: 3.3rem;
  width: 4.5rem;
  border: 0.13rem solid transparent;
  border-radius: 1.13rem;
  animation: skeleton-loading 1s linear infinite alternate;
}

.ExplorerLoader-B2 {
  height: 0.9rem;
  border-radius: 0.3rem;
  animation: skeleton-loading 1s linear infinite alternate;
}

/*SecondExplorerLoader*/

.SecondExplorerLoader-background {
  animation: skeleton-loading 1s linear infinite alternate;
  height: 2rem;
  border-radius: 0.6rem;
}

.first {
  width: 6rem;
}

.second {
  width: 8rem;
}

.third {
  width: 12rem;
}

.fourth {
  width: 10rem;
}

@media (min-width: 1024px) {
  .Explorer-B1::-webkit-scrollbar {
    display: block;
    height: 0.5rem;
  }

  .Explorer-B1::-webkit-scrollbar-track {
    background-color: gainsboro;
  }

  .Explorer-B1::-webkit-scrollbar-thumb {
    background-color: rgb(170, 170, 170);
    border-radius: 1rem;
  }

  .SecondExplorer-txt-1 {
    display: block;
    margin: 0;
    font-size: 0.9rem;
    font-weight: 800;
    color: black;
  }

  .SecondExplorer-B2B1:hover {
    background-color: rgb(39, 150, 255, 0.15);
  }

  .SecondExplorer-B2B1:hover .SecondExplorer-svg-1 {
    fill: #2796ff;
  }

  .SecondExplorer-B2B1:hover .SecondExplorer-txt-2 {
    color: #2796ff;
  }
}
