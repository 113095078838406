.RestaurantData-background {
  grid-column: 1/3;
  border: 0.05rem solid rgb(128, 128, 128, 0.2);
  border-radius: 1.3rem;
  margin: 0 1.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.RestaurantData-B1 {
  padding: 0.8rem;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 0.4rem;
  justify-items: center;
  align-content: flex-start;
}

.bluebackground {
  background-color: rgb(39, 150, 255, 0.15);
}

.pointer {
  cursor: pointer;
}

.RestaurantData-B1B1 {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
}

.RestaurantData-B1B1-Loader {
  animation: skeleton-loading 1s linear infinite alternate;
  height: 1rem;
  width: 3rem;
  border-radius: 0.6rem;
}

.RestaurantData-txt-1 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: gray;
  text-overflow: ellipsis;
  overflow: hidden;
}

.RestaurantData-txt-2 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 800;
  color: black;
  text-align: center;
}

.RestaurantData-txt-2-Loader {
  animation: skeleton-loading 1s linear infinite alternate;
  height: 1rem;
  width: 2rem;
  border-radius: 0.6rem;
}

.blue {
  color: #2796ff;
}

@media (max-width: 360px) {
}

@media (min-width: 648px) {
}

@media (min-width: 1024px) {
  .RestaurantData-background {
    grid-column: 1/2;
    grid-row: 3/4;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, 1fr);
  }

  .RestaurantData-B1 {
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    justify-content: space-between;
  }
}
