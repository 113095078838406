.Products-background {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 1rem;
}

.SectionPageProducts-background {
  height: fit-content;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 2rem;
}

.SectionPageProducts-txt-1 {
  margin: 0;
  line-height: 100%;
  font-size: 1.2rem;
  font-weight: 800;
  color: black;
}

.SectionPageProducts-B1 {
  border-bottom: 0.05rem solid rgb(128, 128, 128, 0.2);
  height: auto;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1.5rem;
  justify-content: space-between;
  align-content: flex-start;
  transition: 0.5s;
  cursor: pointer;
}

.SectionPageProducts-B1:hover {
  transform: scale(1.05);
}

.SectionPageProducts-B1B1 {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 0.5rem;
  align-content: flex-start;
}

.SectionPageProducts-txt-2 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: black;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.SectionPageProducts-txt-3 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: gray;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.4rem;
}

.SectionPageProducts-txt-4 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 800;
  color: black;
}

.SectionPageProducts-img-1 {
  margin: 0 0 1rem 0;
  height: 6rem;
  width: 6rem;
  object-fit: cover;
  border-radius: 1.6rem;
  border: 0.1rem solid white;
  box-shadow: 0px 5px 10px -5px rgba(80, 80, 80, 0.5);
  -webkit-box-shadow: 0px 5px 10px -5px rgba(80, 80, 80, 0.5);
  -moz-box-shadow: 0px 5px 10px -5px rgba(80, 80, 80, 0.5);
}

/*ProductsLoader*/

.Products-background {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 1rem;
}

.Product-background {
  border-bottom: 0.05rem solid rgb(128, 128, 128, 0.2);
  height: auto;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1.5rem;
  justify-content: space-between;
  align-content: flex-start;
}

.Product-B1 {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 0.9rem;
  align-content: flex-start;
}

.Product-B1B1 {
  width: 10rem;
  height: 1rem;
  animation: skeleton-loading 1s linear infinite alternate;
  border-radius: 0.6rem;
}

.Product-B1B1-v0 {
  width: 7rem;
}

.Product-B1B1-v1 {
  height: 2.2rem;
}

.Product-B1B1-v2 {
  width: 5rem;
}

.Product-B2 {
  animation: skeleton-loading 1s linear infinite alternate;
  margin: 0 0 1rem 0;
  height: 6rem;
  width: 6rem;
  border-radius: 1.6rem;
  border: 0.1rem solid white;
}

@media (min-width: 648px) {
  .Products-background {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2.5rem;
  }

  .HardPadding {
    margin-top: 3.2rem;
  }

  .ColumnBreak {
    grid-column-start: 1;
  }
}

@media (min-width: 1024px) {
  .Products-background {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2.5rem;
  }
}
