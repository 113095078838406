.Main-background {
  width: calc(100% - 3rem);
  padding: 0 1.5rem 0 1.5rem;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 1rem;
  align-content: flex-start;
}

.Main-txt-1 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 800;
  color: black;
}

.Main-B1 {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 2rem;
}

.Main-B1B1 {
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.5rem;
  transition: 0.5s;
  text-decoration: none;
}

.Main-B1B1:hover {
  transform: scale(1.05);
}

.Main-img-1 {
  min-height: 9.5rem;
  height: 49vw;
  min-width: 18rem;
  width: 100%;
  object-fit: cover;
  border-radius: 0.6rem;
}

.Main-B1B1B1 {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-content: flex-start;
}

.Main-B1B1B1B1 {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0.7rem;
  justify-content: flex-start;
}

.Main-logo-1 {
  height: 2.3rem;
  width: 2.3rem;
  border-radius: 50%;
  object-fit: cover;
}

.Main-B1B1B1B1B1 {
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.5rem;
  align-content: flex-start;
}

.Main-txt-2 {
  margin: 0;
  font-size: 1rem;
  font-weight: 800;
  color: black;
  line-height: 100%;
}

.Main-B1B1B1B1B1B1 {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
}

.Main-txt-3 {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
  color: black;
}

.Main-txt-4 {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
  color: #2796ff;
}

.Main-B1B1B1B2 {
  height: fit-content;
  width: fit-content;
  padding: 0.2rem 0.4rem;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0.3rem;
  align-items: center;
  background-color: #fff2e5;
  border-radius: 0.5rem;
}

.Main-txt-5 {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 700;
  color: black;
}

/*MainLoader*/

.Main-txt-1-Loader {
  height: 1.1rem;
  width: 8rem;
  border-radius: 0.6rem;
  animation: skeleton-loading 1s linear infinite alternate;
}

.MainLoader-background {
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.5rem;
}

.MainLoader-B1 {
  min-height: 9.5rem;
  height: 49vw;
  min-width: 18rem;
  width: 100%;
  object-fit: cover;
  border-radius: 0.6rem;
  animation: skeleton-loading 1s linear infinite alternate;
}

.MainLoader-B2 {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-content: flex-start;
}

.MainLoader-B2B1 {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0.7rem;
  justify-content: flex-start;
}

.MainLoader-B2B1B1 {
  height: 2.3rem;
  width: 2.3rem;
  border-radius: 50%;
  animation: skeleton-loading 1s linear infinite alternate;
}

.MainLoader-B2B1B2 {
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.5rem;
  align-content: flex-start;
}

.MainLoader-B2B1B2B1 {
  height: 1rem;
  width: 9rem;
  border-radius: 0.6rem;
  animation: skeleton-loading 1s linear infinite alternate;
}

.MainLoader-B2B1B2B2 {
  height: 1rem;
  width: 12rem;
  border-radius: 0.6rem;
  animation: skeleton-loading 1s linear infinite alternate;
}

.MainLoader-B2B2 {
  height: 1.5rem;
  width: 3.5rem;
  border-radius: 0.5rem;
  animation: skeleton-loading 1s linear infinite alternate;
}

@media (min-width: 648px) {
  .Main-B1 {
    grid-template-columns: auto auto;
    grid-column-gap: 2.5rem;
  }

  .Main-img-1 {
    height: 24vw;
    width: 100%;
  }

  .MainLoader-B1 {
    height: 24vw;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .Main-B1 {
    grid-template-columns: auto auto auto;
    grid-column-gap: 2.5rem;
    justify-content: flex-start;
  }

  .Main-img-1 {
    height: 12rem;
    width: 21.5rem;
  }

  .MainLoader-B1 {
    height: 12rem;
    width: 21.5rem;
  }
}
