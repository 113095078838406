.Qualifier-background {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.Qualifier-content {
  background-color: white;
  height: 100%;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  align-content: space-between;
  animation: Qualifier-content-animation ease 0.4s;
}

@keyframes Qualifier-content-animation {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.Qualifier-content-v1 {
  animation: Qualifier-content-v1-animation ease 0.4s;
}

@keyframes Qualifier-content-v1-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}

.Qualifier-B1 {
  display: grid;
  grid-row-gap: 2rem;
  grid-auto-flow: row;
  align-content: flex-start;
}

.Qualifier-B1B1 {
  width: calc(100% - 3rem);
  padding: 1rem 1.5rem 1rem 1.5rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.05rem solid rgb(128, 128, 128, 0.2);
}

.Qualifier-txt-1 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 800;
  color: black;
}

.Qualifier-svg-1 {
  height: 1.3rem;
  width: auto;
  fill: white;
  background-color: rgb(112, 112, 112);
  border-radius: 50%;
  cursor: pointer;
}

.Qualifier-B1B2 {
  width: calc(100% - 3rem);
  padding: 0 1.5rem 0 1.5rem;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 1.3rem;
  align-content: flex-start;
}

.Qualifier-B1B2B1 {
  padding: 1.3rem 0;
  border-top: 0.05rem solid rgb(128, 128, 128, 0.2);
  display: grid;
  grid-row-gap: 1rem;
  grid-auto-flow: row;
  align-content: flex-start;
}

.Qualifier-txt-2 {
  margin: 0;
  font-size: 1rem;
  font-weight: 800;
  color: rgb(50, 50, 50);
  line-height: 100%;
}

.Qualifier-B1B2B1B1 {
  width: fit-content;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.5rem;
  justify-content: flex-start;
  background-color: #fff2e5;
  padding: 0.2rem 0.4rem;
  border-radius: 0.5rem;
}

.Qualifier-txt-3 {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 700;
  color: black;
}

.Qualifier-txt-4 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 700;
  color: gray;
}

.Qualifier-B2 {
  width: calc(100% - 3rem);
  padding: 1rem 1.5rem 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.Qualifier-btn-1 {
  margin: 0;
  background-color: #2796ff;
  padding: 0.8rem 1.5rem;
  border-radius: 3rem;
  color: white;
  font-size: 1rem;
  font-weight: 800;
  border: none;
  transition: 0.2s;
  font-family: "Nunito", sans-serif;
  transition: 1s;
  cursor: pointer;
}

.Qualifier-btn-1-disabled {
  background-color: rgb(200, 200, 200);
  cursor: not-allowed;
}

.Qualifier-shadow {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(44, 44, 44, 0.5);
  z-index: -1;
}

.Qualifier-shadow-v1 {
  background: transparent;
}

/*QualifierCheck*/

.RenderOfQualify-background {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 1.3rem;
  align-content: flex-start;
  justify-content: flex-start;
}

.RenderOfQualify-img-1 {
  height: 3.4rem;
  width: 3.4rem;
  object-fit: cover;
  border-radius: 50%;
}

.RenderOfQualify-txt-1 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 800;
  color: rgb(50, 50, 50);
}

.RenderOfQualify-B1 {
  width: fit-content;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-column-gap: 1rem;
}

.RenderOfQualify-span-1 {
  fill: rgb(200, 200, 200);
  transition: 0.2s;
  cursor: pointer;
}

.span-1-selected {
  fill: #2796ff;
  transition: 0.2s;
}

.RenderOfAlreadyQualified-txt-1 {
  margin: 0;
  font-size: 1rem;
  font-weight: 800;
  color: rgb(50, 50, 50);
  animation: RenderOfAlreadyQualified-txt-1-animaiton 1s;
}

@keyframes RenderOfAlreadyQualified-txt-1-animaiton {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 360px) {
}

@media (min-width: 648px) {
  .Qualifier-background {
    align-items: center;
  }

  .Qualifier-content {
    height: auto;
    width: 29rem;
    border-radius: 0.5rem;
  }
}

@media (min-width: 1024px) {
}
