.Category-background {
  height: 100%;
  width: 100%;
  display: flex;
  align-content: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.Category-B1 {
  position: relative;
  height: fit-content;
  width: calc(100% - 3rem);
  padding: 0 1.5rem 0 1.5rem;
  background-color: white;
  border-radius: 0 0 0.5rem 0.5rem;
  display: grid;
  grid-template-rows: auto 60vh auto;
  align-content: flex-start;
  animation: Category-B1-animation ease 0.4s;
}

@keyframes Category-B1-animation {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.Category-B1-v1 {
  animation: Category-B1-v1-animation ease 0.4s;
}

@keyframes Category-B1-v1-animation {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.Category-txt-1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 800;
  color: black;
  border-bottom: 0.05rem solid rgb(128, 128, 128, 0.2);
  padding: 1rem 0 1rem 0;
  text-align: left;
}

.Category-B1B1 {
  padding: 1rem 0.4rem 1rem 0.4rem;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto auto;
  grid-row-gap: 1.5rem;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
}

.Category-B1B1::-webkit-scrollbar {
  display: none;
}

.Category-txt-2 {
  width: 100%;
  margin: 0;
  padding: 1rem 0 1rem 0;
  font-size: 1rem;
  font-weight: 800;
  color: #2796ff;
  border-top: 0.05rem solid rgb(128, 128, 128, 0.2);
  text-align: center;
  cursor: pointer;
}

.Category-Shadow {
  height: 100%;
  width: 100%;
  background-color: rgb(44, 44, 44, 0.5);
  position: absolute;
  z-index: -1;
}

.Category-Shadow-v1 {
  background: none;
}

/*CategoryFromDB*/

.CategoryFromDB-background {
  height: fit-content;
  width: min-content;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.5rem;
  cursor: pointer;
}

.CategoryFromDB-B1 {
  height: 5.3rem;
  width: 9rem;
  border: 0.13rem solid transparent;
  border-radius: 1.63rem;
  position: relative;
}

.CategoryFromDB-B1-selected {
  border: 0.13rem solid #2796ff;
}

.CategoryFromDB-img-1 {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1.5rem;
}

.CategoryFromDB-img-1-selected {
  opacity: 0.2;
}

.CategoryFromDB-svg-1 {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.CategoryFromDB-txt-1 {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 800;
  color: black;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 0.13rem 0 0.13rem;
}

/*CategoryLoader*/

.CategoryLoader-background {
  height: fit-content;
  width: min-content;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.5rem;
  justify-items: center;
}

.CategoryLoader-B1 {
  height: 5.3rem;
  width: 9rem;
  border: 0.13rem solid transparent;
  border-radius: 1.63rem;
  animation: skeleton-loading 1s linear infinite alternate;
}

.CategoryLoader-B2 {
  animation: skeleton-loading 1s linear infinite alternate;
  height: 0.75rem;
  width: 7rem;
  border-radius: 0.5rem;
}

@media (min-width: 1024px) {
  .Category-background {
    align-items: center;
  }

  .Category-B1 {
    border-radius: 0.5rem;
    width: 30rem;
  }
}

@media (max-width: 360px) {
  .Category-B1B1 {
    grid-template-columns: auto;
    justify-content: center;
  }
}

@media (min-width: 648px) {
  .Category-B1B1 {
    grid-template-columns: auto auto auto;
  }
}
