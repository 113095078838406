.SectionPage-background {
  padding: 3.75rem 0 3.75rem 0;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 1rem;
  grid-template-columns: auto auto;
  align-content: flex-start;
  background-color: white;
}

.SectionPage-B1 {
  grid-column: 1/3;
  height: 9rem;
  width: 100%;
  display: flex;
  position: relative;
  transition: 0.4s;
  z-index: 1;
}

.SectionPage-B1-Scroll {
  position: sticky;
  height: 4rem;
  top: 3.75rem;
}

.SectionPage-img-1 {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 25%;
}

.SectionPage-B1B1 {
  height: 100%;
  width: calc(100% - 3rem);
  padding: 0.7rem 1.5rem 0 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: flex-start;
}

.SectionPage-B1B1-Scroll {
  padding: 0 1.5rem 0 1.5rem;
  align-items: center;
  backdrop-filter: blur(0.3rem);
  box-shadow: 0px 0px 72px 0px rgba(0, 0, 0, 0.8) inset;
  -webkit-box-shadow: 0px 0px 72px 0px rgba(0, 0, 0, 0.8) inset;
  -moz-box-shadow: 0px 0px 72px 0px rgba(0, 0, 0, 0.8) inset;
}

.SectionPage-B1B1B1 {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.SectionPage-svg-1 {
  background-color: rgb(230, 230, 230);
  padding: 0.4rem;
  border-radius: 50%;
  cursor: pointer;
}

.SectionPage-txt-0 {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 800;
  color: white;
  line-height: 100%;
}

.SectionPage-svg-2 {
  background-color: rgb(230, 230, 230);
  padding: 0.2rem;
  border-radius: 50%;
  cursor: pointer;
}

.SectionPage-B2 {
  grid-column: 1/3;
  width: calc(100% - 3rem);
  padding: 0 1.5rem 0 1.5rem;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.65rem;
  justify-content: flex-start;
  align-content: flex-start;
}

.SectionPage-img-2 {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.13);
}

.SectionPage-B2B1 {
  display: grid;
  grid-auto-flow: row;
}

.SectionPage-txt-1 {
  margin: 0;
  font-size: 1.45rem;
  font-weight: 800;
  color: black;
  text-align: left;
}

.SectionPage-txt-2 {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 500;
  color: black;
  text-align: left;
  line-height: 1.45rem;
}

.SectionPage-B3 {
  grid-column: 1/3;
  width: calc(100% - 3rem);
  padding: 1rem 1.5rem;
  border-top: 0.05rem solid rgb(128, 128, 128, 0.2);
  border-bottom: 0.05rem solid rgb(128, 128, 128, 0.2);
  overflow-y: hidden;
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 1.5rem;
}

.SectionPage-B3-Scroll {
  position: sticky;
  top: 7.75rem;
  z-index: 1;
  background-color: white;
}

.SectionPage-B3::-webkit-scrollbar {
  display: none;
}

/*TitulosArray*/

.TitulosArray-background {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.TitulosArray-txt-1 {
  width: max-content;
  margin: 0;
  font-size: 0.9rem;
  font-weight: 800;
  color: bredlack;
}

.TitulosArray-svg-1 {
  display: none;
}

.SectionPage-B4 {
  grid-column: 1/3;
  padding: 0 1.5rem 0 1.5rem;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 2rem;
  align-content: flex-start;
}

.SectionPage-B4B1 {
  display: none;
}

.SectionPage-txt-3 {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 500;
  color: gray;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 360px) {
}

@media (min-width: 648px) {
}

@media (min-width: 1024px) {
  .SectionPage-background {
    grid-template-columns: 15.7rem auto;
  }

  .SectionPage-B1 {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .SectionPage-B2 {
    grid-column: 1/2;
    grid-row: 2/3;
    grid-auto-flow: row;
    grid-row-gap: 0.7rem;
  }

  .SectionPage-img-2 {
    margin: -2.3rem 0 0 0;
    height: 3.5rem;
    width: 3.5rem;
    z-index: 1;
  }

  .SectionPage-B3 {
    grid-column: 1/2;
    grid-row: 4/5;
    grid-auto-flow: row;
    grid-row-gap: 1.5rem;
    justify-content: stretch;
    grid-auto-columns: auto;
  }

  .TitulosArray-background {
    width: 100%;
  }

  .TitulosArray-txt-1 {
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .TitulosArray-svg-1 {
    display: block;
  }

  .SectionPage-B4 {
    grid-column: 2/3;
    grid-row: 1/6;
    padding-top: 1rem;
    border-left: 0.05rem solid rgb(128, 128, 128, 0.2);
  }

  .SectionPage-B4B1 {
    width: fit-content;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 0.4rem;
    justify-content: flex-start;
    align-items: center;
  }
}
