/*SectionPageLoader1*/

.SectionPageLoader1-background {
  height: 1.2rem;
  width: 5rem;
  animation: skeleton-loading 1s linear infinite alternate;
  border-radius: 0.6rem;
}

.Size1 {
  width: 8rem;
}
.Size2 {
  width: 4rem;
}
.Size3 {
  width: 6rem;
}
