.Result-background {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 3.75rem;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  z-index: 2;
}

.Result-content {
  height: fit-content;
  width: 100%;
  background-color: white;
  animation: Result-content-animation ease 0.4s;
  border-radius: 0 0 0.5rem 0.5rem;
}

@keyframes Result-content-animation {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.Result-content-v1 {
  animation: Result-content-v1 ease 0.4s;
}

@keyframes Result-content-v1 {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.Result-content-out {
  height: 100%;
  width: 100%;
  background-color: rgb(44, 44, 44, 0.5);
  position: absolute;
  z-index: -1;
}

.Result-content-out-v1 {
  background: none;
}

/*RenderOfInitialState*/

.RenderOfInitialState-background {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  align-content: flex-start;
}

.RenderOfInitialState-B1 {
  padding: 0.7rem 1.5rem 0.7rem 1.5rem;
  border-top: 0.05rem solid rgb(128, 128, 128, 0.2);
  border-bottom: 0.05rem solid rgb(128, 128, 128, 0.2);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.RenderOfInitialState-txt-1 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 800;
  color: black;
}

.RenderOfInitialState-B2 {
  margin: 1.5rem 0 1.5rem 0;
  padding: 0 1.5rem 0 1.5rem;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto;
  grid-row-gap: 1rem;
}

/*RenderOfFinalState*/

.RenderOfFinalState-background {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  align-content: flex-start;
}

.RenderOfFinalState-B1 {
  padding: 0.7rem 1.5rem 0.7rem 1.5rem;
  border-top: 0.05rem solid rgb(128, 128, 128, 0.2);
  border-bottom: 0.05rem solid rgb(128, 128, 128, 0.2);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.RenderOfFinalState-txt-1 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 800;
  color: black;
}

.RenderOfFinalState-B2 {
  margin: 1.5rem 0 1.5rem 0;
  padding: 0 1.5rem 0 1.5rem;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto;
  grid-row-gap: 1rem;
}

/*RenderOfNotFound*/

.RenderOfNotFound-background {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-row-gap: 1.5rem;
  align-content: flex-start;
  justify-items: center;
  padding: 2rem;
}

.RenderOfNotFound-img {
  height: 5rem;
  width: auto;
}

.RenderOfNotFound-txt-1 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 800;
  color: black;
  text-align: center;
}

/*RenderOfResultdbProducts*/

.RenderOfResultdbProducts-background {
  height: auto;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.RenderOfResultdbProducts-B1 {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}

.RenderOfResultdbProducts-img {
  height: 2rem;
  width: 2rem;
  object-fit: contain;
  border-radius: 0.3rem;
}

.RenderOfResultdbProducts-txt-1 {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 600;
  color: black;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.RenderOfResultdbProducts-txt-2 {
  margin: 0;
  font-size: 0.7rem;
  font-weight: 500;
  color: black;
  background-color: gainsboro;
  border-radius: 0.25rem;
  padding: 0.05rem 0.4rem 0.05rem 0.4rem;
}

/*ResultLoader1*/

.ResultLoader1-background {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.ResultLoader1-B1 {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}

.ResultLoader1-B1B1 {
  animation: skeleton-loading 1s linear infinite alternate;
  height: 2rem;
  width: 2rem;
  border-radius: 0.3rem;
}

.ResultLoader1-B1B2 {
  animation: skeleton-loading 1s linear infinite alternate;
  height: 0.9rem;
  width: 10rem;
  border-radius: 0.3rem;
}

.ResultLoader1-B2 {
  animation: skeleton-loading 1s linear infinite alternate;
  height: 0.9rem;
  width: 4rem;
  border-radius: 0.3rem;
}

@media (min-width: 1024px) {
  .Result-content {
    width: 60vw;
  }
}
