.Information-background {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.Information-content {
  width: 100%;
  background-color: white;
  display: grid;
  grid-auto-flow: row;
  align-content: flex-start;
  border-radius: 0.5rem 0.5rem 0 0;
  animation: Information-content-animation ease 0.4s;
}

@keyframes Information-content-animation {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.Information-content-v1 {
  animation: Information-content-v1-animation ease 0.4s;
}

@keyframes Information-content-v1-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}

.Information-B1 {
  padding: 1rem 1.5rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 0.05rem solid rgb(128, 128, 128, 0.2);
}

.Information-B1B1 {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  justify-content: flex-start;
}

.Information-img-1 {
  height: 3.4rem;
  width: 3.4rem;
  object-fit: cover;
  border-radius: 50%;
}

.Information-B1B1B1 {
  height: fit-content;
  display: grid;
  grid-auto-flow: row;
  align-content: flex-start;
}

.Information-txt-1 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 800;
  color: black;
}

.Information-txt-2 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: gray;
}

.Information-B2 {
  padding: 1rem 1.5rem;
  display: grid;
  grid-row-gap: 1rem;
  grid-auto-flow: row;
}

.Information-B2B1 {
  display: grid;
  justify-content: flex-start;
  padding: 0.5rem 0;
  grid-auto-flow: row;
  grid-row-gap: 0.3rem;
  border-bottom: 0.05rem solid rgb(128, 128, 128, 0.2);
}

.NoBorder {
  border: none;
}

.Information-txt-3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 800;
  color: black;
}

.Information-txt-4 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: gray;
}

.Information-B3 {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  border-top: 0.05rem solid rgb(128, 128, 128, 0.2);
}

.Information-B3B1 {
  background-color: #2796ff;
  display: flex;
  padding: 0.8rem 1.5rem;
  border-radius: 3rem;
  cursor: pointer;
}

.Information-txt-5 {
  margin: 0;
  color: white;
  font-size: 1rem;
  font-weight: 800;
}

.Information-shadow {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(44, 44, 44, 0.5);
  z-index: -1;
}

.Information-shadow-v1 {
  background: transparent;
}

@media (max-width: 360px) {
}

@media (min-width: 648px) {
  .Information-background {
    align-items: center;
  }

  .Information-content {
    width: 29rem;
    border-radius: 0.5rem;
  }
}

@media (min-width: 1024px) {
}
